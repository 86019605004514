import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-light light-blue lighten-4 d-sm-none">
          <a className="navbar-brand" href="/">
            <img
              src="/uploads/voorlichtingsles-logo.png"
              height="40"
              alt="Voorlichtingsles Volwassen Worden"
            />
          </a>
          <button
            className="navbar-toggler toggler-example"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="dark-blue-text">
              <i className="fas fa-bars fa-1x" />
            </span>
          </button>
          <div
            className="collapse navbar-collapse"
            id="navbarSupportedContent1"
          >
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lessen"
                >
                  Lessen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lesmaterialen"
                >
                  Lesmaterialen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/multimedia"
                >
                  Multimedia aanpak
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/lesmaterialen/lespakket-gratis-bestellen"
                >
                  Lespakket gratis bestellen
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link waves-effect waves-light"
                  href="/service"
                >
                  Service
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <header className="bg-blue d-none d-sm-block pt-3">
          <div
            className="container-fluid"
            style={{
              "border-bottom": "rgb(238,38,110) 7px solid"
            }}
          >
            <div className="container">
              <div className="row hideMegaMenu">
                <div className="col-12">
                  <img
                    src="/uploads/voorlichtingsles-logo.png"
                    alt="Voorlichtingsles Volwassen Worden"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <a className="show-menu" href="/" data-value="-1">
                    <i className="fas fa-home fa-2x" />
                  </a>
                  <a className="show-menu" href="/lessen" data-value="0">
                    Lessen
                  </a>
                  <a
                    className="show-menu"
                    href="/lesmaterialen"
                    data-value="1"
                  >
                    Lesmaterialen
                  </a>
                  <a
                    className="show-menu"
                    href="/multimedia"
                    data-value="2"
                  >
                    Multimedia aanpak
                  </a>
                  <a
                    className="show-menu"
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    data-value="3"
                  >
                    Lespakket gratis bestellen
                  </a>
                  <a className="show-menu" href="/service" data-value="4">
                    Service
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="megamenu container-fluid"
            data-show="0"
            style={{
              "z-index": "99999",
              position: "absolute",
              display: "none"
            }}
          >
            <div id="megamenu_0" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_richtlijnen.jpg"
                      className="img-fluid"
                      alt="Richtlijnen"
                    />
                    <a
                      href="/lessen/richtlijnen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Richtlijnen voor de seksuele voorlichting
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_lesvoorbereiding.jpg"
                      className="img-fluid"
                      alt="Lesvoorbereiding"
                    />
                    <a
                      href="/lessen/lesvoorbereiding"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Lesvoorbereiding
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_1_tips.jpg"
                      className="img-fluid"
                      alt="Tips"
                    />
                    <a
                      href="/lessen/tips"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Tips voor de seksuele voorlichtingslessen
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_1" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/themagebieden"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Themagebieden
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/themagebieden/ontwikkeling-en-puberteit"
                            className="d-block mt-1 tab-menu"
                          >
                            Ontwikkeling en puberteit
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/geslachtsorganen"
                            className="d-block mt-1 tab-menu"
                          >
                            Geslachtsorganen
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/cyclus-en-menstruatie"
                            className="d-block mt-1 tab-menu"
                          >
                            Menstruatiecyclus
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/menstruatie-hygine"
                            className="d-block mt-1 tab-menu"
                          >
                            Menstruatie hygiëne
                          </a>
                          <a
                            href="/lesmaterialen/themagebieden/tampongebruik"
                            className="d-block mt-1 tab-menu"
                          >
                            Tampongebruik
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/speciale-pakketten"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Speciale pakketten
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-meisjes"
                            className="d-block mt-1 tab-menu"
                          >
                            Lesmateriaal voor meisjes
                          </a>
                          <a
                            href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                            className="d-block mt-1 tab-menu"
                          >
                            Lesmateriaal voor jongens
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul className="nav navigatie">
                      <li className="font-weight-bold">
                        <a
                          href="/lesmaterialen/speciale-pakketten"
                          style={{
                            color: "inherit"
                          }}
                        >
                          Lespakket gratis bestellen
                        </a>
                        <div>
                          <a
                            href="/lesmaterialen/lespakket-gratis-bestellen/docentenhandleiding"
                            className="d-block mt-1 tab-menu"
                          >
                            Docentenhandleiding
                          </a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_2" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_instructiefilm.jpg"
                      className="img-fluid"
                      alt="Instructiefilm"
                    />
                    <a
                      href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Instructiefilm over seksuele voorlichting «Volwassen
                      worden»
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_kennistest.jpg"
                      className="img-fluid"
                      alt="Online kennistest"
                    />
                    <a
                      href="/multimedia/online-kennistests"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Online kennistest
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_3_oefeningen.jpg"
                      className="img-fluid"
                      alt="Oefenbladen"
                    />
                    <a
                      href="/multimedia/oefenbladen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Oefenbladen
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_3" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_4_primaironderwijs.jpg"
                      className="img-fluid"
                      alt="Bestel nu gratis"
                    />
                    <a
                      href="/lesmaterialen/lespakket-gratis-bestellen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Bestel nu gratis
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
            <div id="megamenu_4" className="container">
              <div className="container py-5">
                <div className="row">
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_belangrijke_vragen.jpg"
                      className="img-fluid"
                      alt="Belangrijke vragen"
                    />
                    <a
                      href="/service/veel-gestelde-vragen"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Belangrijke vragen over menstruatie en menstruatiehygiëne
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_belangrijke_links.jpg"
                      className="img-fluid"
                      alt="Service"
                    />
                    <a
                      href="/service/belangrijke-links"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Belangrijke links
                    </a>
                  </div>
                  <div className="col-md-3">
                    <img
                      src="/uploads/layer_5_contact.jpg"
                      className="img-fluid"
                      alt="Contact"
                    />
                    <a
                      href="/service/contact"
                      className="d-block mt-1 tab-menu stretched-link"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-fluid borderd" />
            </div>
          </div>
        </header>
        <main className="hideMegaMenu">
          <div className="container-fluid opening mb-3">
            <div className="container bg-paars">
              <div className="row">
                <div className="col-md-6 px-4 py-3">
                  <h1 className="h1-responsive text-white">
                    Veel gestelde vragen
                  </h1>
                </div>
                <div className="col-md-6">
                  <img
                    src="/uploads/img_4459_6_service.jpg"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid hideMegaMenu">
            <div className="container">
              <div id="breadcrumb">
                <div className="bc my-3 d-none d-sm-block ">
                  <a href="/">Home</a> &gt;{" "}
                  <a href="/service">Service &amp; veel gestelde vragen</a>{" "}
                  &gt;{" "}
                  <a href="/service/veel-gestelde-vragen">
                    Veel gestelde vragen
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div id="content">
                    <h1 className="h1-responsive my-3  ">
                      Veel gestelde vragen
                    </h1>
                    <p className="lead hide" />
                    <p />
                    <div className="B00561">
                      <div className="faq">
                        <h3 className="mt-3">
                          De meest gestelde vragen over de menstruatie
                        </h3>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4562"
                          />{" "}
                          Hoe zwaar is de menstruatiebloeding?
                        </p>
                        <div
                          id="uitleg-element-docid-4562"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            De sterkte van de menstruatie varieert van meisje
                            tot meisje en van vrouw tot vrouw en verandert in de
                            loop van de periode. Het bloeden is zwaarder in de
                            eerste paar dagen en zwakker tegen het einde van de
                            periode. Gemiddeld verlies je meestal ongeveer 65 ml
                            menstruatievocht dat voornamelijk bestaat uit bloed
                            en opgelost baarmoederslijmvlies. De totale
                            hoeveelheid komt ongeveer overeen met de inhoud van
                            een half kopje.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4563"
                          />{" "}
                          Wanneer ga je voor het eerst menstrueren?
                        </p>
                        <div
                          id="uitleg-element-docid-4563"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Tussen de 10 en 15 jaar oud – de meeste meisjes zijn
                            rond de 12 of 13 jaar oud. De eerste menstruatie is
                            een belangrijke stap in de ontwikkeling van het
                            lichaam die de meisjes aangeeft dat ze vruchtbaar
                            zijn geworden en een kind kunnen krijgen.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4564"
                          />{" "}
                          Hoe bereken je de volgende periode?
                        </p>
                        <div
                          id="uitleg-element-docid-4564"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Door 28 dagen te tellen vanaf de eerste dag van de
                            menstruatie. Dit is een gemiddelde. De volgende
                            menstruatie kan gemiddeld ongeveer 25 tot 31 dagen
                            na de eerste dag van de bloeding worden verwacht.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4565"
                          />{" "}
                          Wat betekent TSS?
                        </p>
                        <div
                          id="uitleg-element-docid-4565"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Toxic Shock Syndrome (TSS) is een uiterst zeldzame
                            maar ernstige toxische ziekte die kan voorkomen bij
                            mannen, vrouwen en kinderen. Het wordt veroorzaakt
                            door toxines van een bacterie die vaak bij mensen
                            wordt gevonden. Ongeveer de helft van de TSS
                            gevallen komt voor bij menstruerende vrouwen en
                            meisjes. TSS is beschreven in verband met het
                            gebruik van tampons, maar kan ook voorkomen tijdens
                            de menstruatie als er geen tampons worden gebruikt.
                            TSS kan levensbedreigend zijn en moet daarom
                            vroegtijdig worden herkend en behandeld. Als je je
                            tijdens je menstruatieperiode plotseling heel ziek
                            voelt bijv. als je hoge koorts krijgt (39 ℃ of
                            hoger), braken, diarree, hoofdpijn, keelpijn,
                            duizeligheid of flauwvallen, spierpijn of een
                            zonnebrandachtige uitslag, moet de tampon uit
                            voorzorg worden verwijderd en een arts moet
                            onmiddellijk worden geraadpleegd. De mogelijkheid
                            van een TSS-ziekte moet daarbij worden vermeld. De
                            arts moet ook worden geïnformeerd dat je je
                            menstruatie hebt. TSS kan herhaaldelijk voorkomen
                            ongeacht of je tampons gebruikt of niet. Als je TSS
                            hebt gehad, moet je eerst je arts raadplegen voordat
                            je tampons gebruikt.
                          </p>
                        </div>
                        <h3 className="mt-3">
                          De meest gestelde vragen over tampons en maandverband
                        </h3>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4566"
                          />{" "}
                          Wat is het verschil tussen maandverband en een tampon?
                        </p>
                        <div
                          id="uitleg-element-docid-4566"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Een maandverband wordt uitwendig gedragen. Het wordt
                            met een plakstrip aan het slipje bevestigd en
                            absorbeert alleen de menstruatievloeistof wanneer
                            het uit de vagina is gestroomd. In tegenstelling tot
                            maandverband absorbeert een tampon de
                            menstruatievloeistof in het lichaam voordat deze het
                            lichaam verlaat. Dus alles blijft aangenaam droog en
                            schoon buiten.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4567"
                          />{" "}
                          Welke tampons zijn de juiste?
                        </p>
                        <div
                          id="uitleg-element-docid-4567"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p />
                          <p>
                            Tampons in verschillende maten en absorptievermogens
                            helpen om de menstruatiebescherming aan te passen
                            aan de respectieve hoeveelheid bloeding. Om het je
                            gemakkelijker te maken de juiste tampongrootte te
                            kiezen, afhankelijk van de ernst van de bloeding,
                            hebben tampons niet alleen verschillende maten zoals
                            “mini” of “normaal” maar worden ze ook
                            internationaal gelabeld met druppelsymbolen op basis
                            van hun absorptievermogen. Hoe meer druppels op een
                            verpakking, hoe meer vloeistof de individuele tampon
                            kan bevatten.
                          </p>
                          <p>
                            <b>Absorptievermogen</b>
                            <br />
                            De figuur toont een overzicht van het aantal
                            druppels en de bijbehorende absorptiecapaciteit van
                            de verschillende tampons van verschillende grootte.
                            Om vertrouwd te raken met het gebruik van tampons is
                            het nuttig om bijv. een o.b ProComfort™ Mini te
                            gebruiken. Naast de gebogen groeven en het
                            zijdezachte oppervlak heeft het een extra slanke
                            vorm met een smalle punt en is korter dan de andere
                            tampons. Later, wanneer je bekend bent met het
                            gebruik van tampons, zul je snel ontdekken op welke
                            dagen van de periode welke tampon de juiste is.
                            Kortom, een tampon moet worden gebruikt die
                            overeenkomt met de individuele bloedingsintensiteit
                            en altijd met de laagst mogelijke
                            absorptiecapaciteit.
                          </p>
                          <p>
                            <a
                              href="https://teens.ob-tampons.nl/ontdek-ob-tampons"
                              target="_blank"
                            >
                              Ga naar de productzoeker
                            </a>
                          </p>
                          <p />
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4568"
                          />{" "}
                          Kunnen tampons gebruikt worden als je nog maagd bent?
                        </p>
                        <div
                          id="uitleg-element-docid-4568"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            In principe kunnen meisjes tampons gebruiken vanaf
                            de eerste periode. Het maagdenvlies dat zich
                            onderaan de voorkant in de vaginale ingang bevindt
                            heeft een natuurlijke opening waardoor de vaginale
                            afscheidingen en het menstruatiebloed kunnen
                            wegvloeien. Door de hormonale veranderingen die aan
                            de eerste periode voorafgaan is het maagdenvlies
                            meestal een zachte rekbare huidplooi geworden zodat
                            kleinere tamponafmetingen (o.b.® ProComfort ™ Mini,
                            Light Days en Normal) gemakkelijk kunnen worden
                            gebruikt zonder het maagdenvlies te beschadigen.
                            Jonge meisjes zouden bijvoorbeeld kunnen beginnen
                            met de o.b.® ProComfort ™ Mini omdat deze bijzonder
                            gemakkelijk te plaatsen is.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4569"
                          />{" "}
                          Wanneer vervang je een tampon?
                        </p>
                        <div
                          id="uitleg-element-docid-4569"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p />
                          <p>
                            Hoe vaak een tampon moet worden vervangen hangt af
                            van de sterkte van de bloeding. Het is echter
                            belangrijk dat de tampon regelmatig wordt vervangen.
                            Bij een sterkere bloeding kan de eerste dagen na 3
                            tot 6 uur een wisseling nodig zijn. Op de laatste
                            dagen volstaat meestal een kleinere tampon die 6 tot
                            8 uur kan worden gebruikt.
                          </p>
                          <p>Tips:</p>
                          <ol>
                            <li>
                              Je kunt zelf vaststellen of de tampon vervangen
                              moet worden of niet. Je hoeft alleen maar
                              voorzichtig aan het terugtrekkoordje te trekken.
                              Als de tampon onder voorzichtig trekken loslaat en
                              gemakkelijk glijdt is het tijd om hem te
                              vervangen. Als hij dat niet doet is het nog niet
                              nodig.
                            </li>
                            <li>
                              De tampon hoeft niet elke keer dat je naar het
                              toilet gaat te worden vervangen, omdat de
                              urinebuis, de vagina en de darm afzonderlijke
                              organen zijn elk met een eigen opening. Je kunt
                              het terugtrekkoord opzij houden om te voorkomen
                              dat het nat wordt.
                            </li>
                          </ol>
                          <p />
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4570"
                          />{" "}
                          Kun je tampons ‘s nachts gebruiken?
                        </p>
                        <div
                          id="uitleg-element-docid-4570"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Tampons kunnen ‘s nachts veilig worden gebruikt. In
                            tegenstelling tot verband, kunnen tampons tijdens
                            het slapen niet wegglijden. De tampon moet voor het
                            slapengaan en ’s morgens direct na het opstaan
                            worden vervangen. Dezelfde tamponmaat kan zowel ’s
                            nachts als overdag gebruikt worden. ’S Nachts kun je
                            er langer mee doen omdat de menstruatie ’s nachts
                            meestal wat milder is.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4571"
                          />{" "}
                          Kun je tijdens het sporten tampons gebruiken?
                        </p>
                        <div
                          id="uitleg-element-docid-4571"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            In principe kun je tijdens de menstruatie alles doen
                            wat je op andere dagen ook doet en dus te bewegen
                            zoals je gewend bent. Natuurlijk kun je ook sporten
                            en zelfs zwemmen met tampons. Het is belangrijk dat
                            je je prettig voelt bij wat je doet. Sporten helpt
                            vaak bij het verlichten van normale klachten.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4572"
                          />{" "}
                          Zijn tampons medisch en hygiënisch verantwoord?
                        </p>
                        <div
                          id="uitleg-element-docid-4572"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            De meest gebruikte tampon is de o.b.® tampon. Deze
                            werd in 1948 ontwikkeld met medewerking van een
                            gynaecoloog en wordt al meer dan 60 jaar gemaakt
                            volgens de nieuwste wetenschappelijke bevindingen en
                            onder de strengste kwaliteitseisen. De absorberende
                            vezels die voor de o.b.® tampon worden gebruikt,
                            voldoen aan de hygiëne eisen van de Europese
                            farmaceutische regels voor wattenverband. Miljoenen
                            vrouwen wereldwijd gebruiken o.b.® tampons. Het
                            aantal gebruikers en talloze wetenschappelijke
                            onderzoeken hebben aangetoond dat de o.b.® tampons
                            medisch en hygiënisch uitstekend zijn.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4573"
                          />{" "}
                          Kan een tampon in het lichaam verdwalen?
                        </p>
                        <div
                          id="uitleg-element-docid-4573"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Een tampon kan niet in het lichaam verdwalen. De
                            ingang van de baarmoeder is veel te klein om de
                            tampon door te laten.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4574"
                          />{" "}
                          Wat kun je doen als je het terugtrekkoordje niet meer
                          kunt vinden?
                        </p>
                        <div
                          id="uitleg-element-docid-4574"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Als je het terugtrekkoordje niet meer kunt vinden
                            wacht dan tot de tampon na enige tijd volledig
                            volgezogen is. Door te hurken komt de tampon naar
                            beneden en kun je hem met twee vingers vastpakken.
                            Als het een keer écht niet lukt om de tampon te
                            verwijderen moet je naar de huisarts.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4575"
                          />{" "}
                          Kan het koordje van de tampon breken?
                        </p>
                        <div
                          id="uitleg-element-docid-4575"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Het terugtrekkoordje wordt om de katoenen strook
                            gelust en vastgebonden voordat de tampon in zijn
                            vorm wordt gemaakt zodat het koordje stevig wordt
                            verankerd in de tampon. Om jezelf te overtuigen kun
                            je eenvoudig stevig trekken voordat je de tampon
                            inbrengt. Je kunt zien dat hij echt stevig vastzit
                            aan de tampon.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4576"
                          />{" "}
                          Kunnen tampons de bloeding tegenhouden?
                        </p>
                        <div
                          id="uitleg-element-docid-4576"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Een tampon kan de menstruatievloeistof niet
                            tegenhouden. Het absorbeert vloeistof en geeft het
                            door tot de tampon doordrenkt is. Een volle tampon
                            die niet op tijd wordt vervangen laat de vloeistof
                            door.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4577"
                          />{" "}
                          Wat is maandverband en wanneer gebruik je die?
                        </p>
                        <div
                          id="uitleg-element-docid-4577"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p />
                          <p>
                            De natuurlijke vaginale afscheiding is iets heel
                            normaals in het leven van een vrouw. Het soort en de
                            hoeveelheid afscheiding variëren van vrouw tot vrouw
                            en worden beïnvloed door de hormonale schommelingen
                            in de loop van de natuurlijke cyclus. Bacteriën
                            kunnen zich bijzonder goed voortplanten in het
                            warme, vochtige milieu van de schaamstreek, waaraan
                            onder meer ook afscheidingen uit de vagina
                            bijdragen. Om huidirritatie te voorkomen moet de
                            schaamstreek daarom altijd zo schoon en droog
                            mogelijk worden gehouden. Inlegkruisjes helpen door
                            het absorberen van overtollig vocht. In
                            tegenstelling tot maandverband zijn ze meestal
                            smaller, korter en dunner en worden ook in het
                            slipje geplakt. Terwijl maandverband wordt gebruikt
                            om de menstruatie te beginnen worden inlegkruisjes
                            voornamelijk gedragen op de dagen tussen bloeden als
                            onderdeel van de dagelijkse verzorging.
                          </p>
                          <ul>
                            <li>
                              Voor het opnemen van vaginale afscheiding
                              <br />
                              Veel jonge meisjes hebben één tot twee jaar voor
                              de eerste menstruatie last van vaginale
                              afscheiding, de zogenaamde witte vloed. Een teken
                              dat de geslachtsorganen actief zijn door hormonen.
                              Ook later – tussen de menstruaties in - kan een
                              natuurlijke vaginale afscheiding voorkomen.
                            </li>
                          </ul>
                          <ul>
                            <li>
                              In het geval van een onregelmatige cyclus
                              <br />
                              Bij jonge meisjes is de cyclus de eerste jaren na
                              het begin van de menstruatie nog steeds
                              onregelmatig waardoor het vaak moeilijk is in te
                              schatten wanneer de menstruatie begint. Een
                              inlegkruisje beschermt dan tegen verrassingen.
                            </li>
                            <li>
                              Bij onzekerheden tijdens het gebruik van tampons
                              <br />
                              De combinatie met het gebruik van maandverband
                              zorgt voor extra zekerheid, zeker als je nog niet
                              bekend bent met het gebruik van een tampon en niet
                              precies weet wanneer je de tampon moet vervangen.
                            </li>
                            <li>
                              Tijdens de laatste dagen van de menstruatie met
                              zeer lichte bloedingen.
                            </li>
                            <li>
                              Bij bloedingen tussen de menstruaties door onder
                              invloed van de pil.
                            </li>
                          </ul>
                          <p>
                            Natuurlijk vervangen inlegkruisjes het dagelijkse
                            schone ondergoed en de normale persoonlijke hygiëne
                            niet maar ze helpen je om je fris en volledig
                            comfortabel te voelen. Zachte inlegkruisjes, die
                            comfortabel zitten en ook ademend zijn, zijn zeer
                            geschikt voor jonge meisjes zoals het bijzonder
                            zachte CAREFREE™ &nbsp;Flexiform.
                          </p>
                          <p />
                        </div>
                        <h3 className="mt-3">
                          De meest gestelde vragen over menstruatieklachten
                        </h3>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4578"
                          />{" "}
                          Hoe worden menstruatieklachten veroorzaakt{" "}
                        </p>
                        <div
                          id="uitleg-element-docid-4578"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p>
                            Om het loslaten van de bovenste lagen van het
                            slijmvlies te ondersteunen trekt de baarmoeder aan
                            het begin van de menstruatie herhaaldelijk samen.
                            Misschien voel je een lichte trek in de onderbuik.
                            Bij sommige vrouwen en meisjes trekt de baarmoeder
                            zoveel samen dat het pijn doet.
                          </p>
                        </div>
                        <p>
                          <i
                            className="fas fa-info-circle"
                            data-value="docid-4579"
                          />{" "}
                          Wat helpt tegen menstruatieklachten?
                        </p>
                        <div
                          id="uitleg-element-docid-4579"
                          className="uitleg note note-info mt-2"
                          style={{
                            display: "none",
                            "font-size": "smaller"
                          }}
                        >
                          <p />
                          <p>
                            Wat goed is, is goed.&nbsp; Er zijn verschillende
                            manieren om je tijdens de menstruatie goed te voelen
                            en plezier te hebben.
                          </p>
                          <ul>
                            <li>
                              Afleiding zoeken: leuke dingen doen zoals
                              bijvoorbeeld vrienden ontmoeten, shoppen, muziek
                              luisteren of je met je hobby bezig te houden.
                            </li>
                            <li>
                              Beweging: bijvoorbeeld wandelen of fietsen in de
                              frisse lucht.
                            </li>
                            <li>
                              Ontspanning: gericht zorgen voor een goede
                              doorbloeding, bijvoorbeeld met een warme kruik,
                              een warme douche of een warm bad. Warme dranken
                              (bijvoorbeeld een kruidenthee) verwarmen je van
                              binnen. In het algemeen geldt dat warmte je
                              bloedvaten verweidt en ontspant.
                            </li>
                            <li>Een goede nachtrust</li>
                            <li>
                              Met tampons hebben meisjes en vrouwen altijd de
                              mogelijkheden om actief te zijn, ook gedurende de
                              menstruatie. Als de menstruatie zonder klachten
                              verloopt, men gezond is en zich goed voelt dan is
                              er geen reden iets aan je gewone leven te
                              veranderen.&nbsp;
                            </li>
                          </ul>
                          <p>
                            Wat als de symptomen niet verdwijnen ondanks
                            ontspanning en het gebruik van huismiddeltjes?
                          </p>
                          <p>
                            Als de beschreven huismiddeltjes niet helpen aarzel
                            dan niet om deskundig advies te vragen aan je
                            huisarts. Sommige meisjes en vrouwen hebben
                            symptomen tijdens de menstruatie, zoals overmatig of
                            langdurig bloeden, krampachtige pijn in de onderbuik
                            of voelen zich algeheel onwel. Dit moet je bespreken
                            met je huisarts.
                          </p>
                          <p />
                        </div>
                      </div>
                    </div>
                    <p />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="bg-blue zij-menu p-3">
                    <a href="/service" className="level-1 d-block ">
                      Service &amp; veel gestelde vragen
                    </a>
                    <a
                      href="/service/veel-gestelde-vragen"
                      className="level-2 d-block text-rood"
                    >
                      Veel gestelde vragen
                    </a>
                    <a
                      href="/service/belangrijke-links"
                      className="level-2 d-block "
                    >
                      Belangrijke links
                    </a>
                    <a
                      href="/service/contact"
                      className="level-2 d-block "
                    >
                      Contact
                    </a>
                    <a
                      href="/service/juridische-kennisgeving"
                      className="level-2 d-block "
                    >
                      Juridische kennisgeving
                    </a>
                    <a
                      href="/service/privacybeleid"
                      className="level-2 d-block "
                    >
                      Privacybeleid
                    </a>
                    <a
                      href="/service/cookies"
                      className="level-2 d-block "
                    >
                      Cookiebeleid
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div className="B00544">
          <div className="container-fluid pb-3">
            <div className="container">
              <div className="row mr-md-5 pr-md-5">
                <div className="col-md-4 text-center d-none">
                  <a
                    href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout1_jongen.png"
                      className="img-fluid p-3"
                      alt="Materiaal voor jongens"
                    />
                  </a>
                  <h3>Materiaal voor jongens</h3>
                  <div className="pay-off">
                    Lesmateriaal rondom het thema puberteit bij jongens
                  </div>
                  <a
                    href="/lesmaterialen/speciale-pakketten/lesmateriaal-voor-jongens"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu downloaden
                  </a>
                </div>
                <div className="col-md-4 text-center ">
                  <a
                    href="/lesmaterialen/speciale-pakketten"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout2_primair_onderwijs.png"
                      className="img-fluid p-3"
                      alt="Materiaal voor basisscholen"
                    />
                  </a>
                  <h3>Materiaal voor basisscholen</h3>
                  <div className="pay-off">
                    Lesmateriaal voor seksuele voorlichtingslessen
                  </div>
                  <a
                    href="/lesmaterialen/speciale-pakketten"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu samenstellen
                  </a>
                </div>
                <div className="col-md-4 text-center ">
                  <a
                    href="/lessen/lesvoorbereiding"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout3_lesvoorbereiding.png"
                      className="img-fluid p-3"
                      alt="Hulp bij de lesvoorbereiding"
                    />
                  </a>
                  <h3>Hulp bij de lesvoorbereiding</h3>
                  <div className="pay-off">
                    Tips en checklists voor seksuele voorlichtingslessen
                  </div>
                  <a
                    href="/lessen/lesvoorbereiding"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu informeren
                  </a>
                </div>
                <div className="col-md-4 text-center ">
                  <a href="/lesmaterialen" className="stretched-link">
                    <img
                      src="/uploads/callout5_lesmateriaal.png"
                      className="img-fluid p-3"
                      alt="Download lesmaterialen"
                    />
                  </a>
                  <h3>Download lesmaterialen</h3>
                  <div className="pay-off">
                    Uitgebreid aanbod van onderwerpen voor de seksuele
                    voorlichting
                  </div>
                  <a
                    href="/lesmaterialen"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Naar de materialen
                  </a>
                </div>
                <div className="col-md-4 text-center d-none">
                  <a href="/multimedia" className="stretched-link">
                    <img
                      src="/uploads/callout6_multimedia.png"
                      className="img-fluid p-3"
                      alt="Multimedia voor seksuele voorlichting"
                    />
                  </a>
                  <h3>Multimedia voor seksuele voorlichting</h3>
                  <div className="pay-off">
                    Instructiefilm en interactieve kennistests voor seksuele
                    voorlichting
                  </div>
                  <a
                    href="/multimedia"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu bekijken
                  </a>
                </div>
                <div className="col-md-4 text-center d-none">
                  <a
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    className="stretched-link"
                  >
                    <img
                      src="/uploads/callout4_lespakket_bestellen.png"
                      className="img-fluid p-3"
                      alt="Gratis lespakket"
                    />
                  </a>
                  <h3>Gratis lespakket</h3>
                  <div className="pay-off">
                    Relevante lesmaterialen voor seksuele voorlichting
                  </div>
                  <a
                    href="/lesmaterialen/lespakket-gratis-bestellen"
                    className="btn text-white bg-dark-blue waves-effect waves-light mt-3"
                  >
                    Nu bestellen
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <div className="container pt-3 hideMegaMenu">
            <div className="text-center">
              <img
                className="pb-5"
                style={{
                  width: "290px"
                }}
                src="/uploads/ob-logo.png"
                alt="o.b. professioneel forum"
              />
            </div>
            <div className="row">
              <div className="col-md-4">
                <a className="main" href="/">
                  Homepage
                </a>
                <a href="/service/sitemap">Sitemap</a>
                <a href="/service/contact">Contact</a>
                <a href="/service/juridische-kennisgeving">
                  Juridische kennisgeving
                </a>
                <a href="/service/privacybeleid">Privacy statement</a>
                <a href="/service/cookies">Cookie statement</a>
              </div>
              <div className="col-md-4">
                <a
                  className="main"
                  href="/lesmaterialen/lespakket-gratis-bestellen"
                >
                  Lespakket gratis bestellen
                </a>
                <a href="/lesmaterialen/lespakket-gratis-bestellen">
                  Bestel het nu
                </a>                  
              </div>
              <div className="col-md-4">
                <a className="main" href="/multimedia">
                  Multimedia voor seksuele voorlichting
                </a>
                <a href="/multimedia/instructiefilm-over-seksuele-voorlichting-volwassen-worden">
                  Educatieve film "Opgroeien"
                </a>
                <a href="/multimedia/online-kennistests/quiz-puberteit">
                  Quiz puberteit
                </a>
                <a href="/multimedia/online-kennistests/quiz-menstruatie">
                  Quiz menstruatie
                </a>
                <a href="/multimedia/online-kennistests/quiz-menstruatie-hygiene">
                  Quiz menstruatiehygiëne
                </a>
                <a href="/multimedia/oefenbladen">Oefenbladen</a>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-4">
                <a className="main" href="/lesmaterialen">
                  Lesmaterialen voor seksuele voorlichtingslessen
                </a>
                <a href="/lesmaterialen/themagebieden">Themagebieden</a>
                <a href="/lesmaterialen/speciale-pakketten">
                  Speciale pakketten
                </a>
              </div>
              <div className="col-md-4">
                <a className="main" href="/lessen">
                  Lesgeven
                </a>
                <a href="/lessen/richtlijnen">
                  Richtlijnen voor seksuele voorlichting
                </a>
                <a href="/lessen/lesvoorbereiding">Lesvoorbereiding</a>
                <a href="/lessen/tips">Tips voor seksuele voorlichting</a>
              </div>
              <div className="col-md-4">
                <a className="main" href="/service">
                  Service en veelgestelde vragen
                </a>
                <a href="/service/veel-gestelde-vragen">
                  Veelgestelde vragen
                </a>
                <a href="/service/belangrijke-links">Belangrijke links</a>
                <a href="/service/contact">Contact &amp; feedback</a>
              </div>
            </div>
            <div className="row mt-5 pb-3">
              <div className="col-12 font-smaller">
                <p>© Johnson &amp; Johnson Consumer B.V. 2006 - 2019.</p>
                <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-instellingen</button>
                <p style={{"float": "left"}}>
                  Deze website is gepubliceerd door Johnson &amp; Johnson                               
                  Consumer B.V., die als enige verantwoordelijk is voor de
                  inhoud. De site is bedoeld voor een Nederlands publiek.
                  <br />
                  Voor het laatst bijgewerkt: 14 oktober 2019.
                </p>
              </div>
            </div>
          </div>
        </footer>
        <script type="text/javascript" src="/js/jquery-3.5.1.js" />
        <script type="text/javascript" src="/js/popper.min.js" />
<script type="text/javascript" src="/js/jquery-migrate-3.3.0.js" />
        <script type="text/javascript" src="/js/bootstrap.min.js" />
        <script type="text/javascript" src="/js/mdb.min.js" />
        <div className="hiddendiv common" />
        <script type="text/javascript" src="/system/js/user.js" />
      </div>
    );
  }
}

export default Page;
